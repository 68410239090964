<template>
  <div style="background: #f6f6f6;color: #000;">
    <van-nav-bar title="收入审核(出纳专用)详情" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <div class="nav-bar">
      <van-row class="topss">
        <van-icon @click="timeshang" name="play" style="transform: rotate(180deg)" />
        <span @click="timeshow=true">{{bizDate}}</span>
        <van-icon @click="timexia" name="play" />
      </van-row>
      <van-row class="mian_row" style="font-weight: bold;font-size:13px;">
        <van-col span="2">ID</van-col>
        <van-col span="3">时间</van-col>
        <van-col span="4">车牌</van-col>
        <van-col span="3" class="font_10">支付方式</van-col>
        <van-col span="4">金额</van-col>
        <van-col span="3" style="font-size:11.5px;">凭证图</van-col>
        <van-col span="5" style="text-align: right;padding-right: 20px;">操作</van-col>
      </van-row>
    </div>
    <div class="pt" v-if="list.length > 0">
      <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了" @load="onLoads"
        :offset="10">
        <div class="mian" v-for="(item, idx) in list" :key="idx">
          <van-row class="mian_row">
            <van-col span="2">{{ item.id }}</van-col>
            <van-col span="3">{{ item.bizDate?item.bizDate.substring(11,16):'' }}</van-col>
            <van-col span="4">
              <span :class="(item.carNo=='同行联合'||item.carNo=='代定联合')?'line carNo':'carNo'" @click="tourls(item)">{{ item.carNo }}</span>
            </van-col>
            <van-col span="3" class="slh">{{ item.payTypeStr }}</van-col>
            <van-col span="4">{{ item.total }}</van-col>
            <van-col span="3"><img style="width:40px;height:30px;vertical-align: middle;" @click="toimgdan(item.pic)" v-if="item.pic" :src="item.pic" alt=""></van-col>
            <van-col span="5" style="text-align:right;">
              <el-select :class="item.examineStatus=='已审核'?'cGreen':'cRed'" v-model="item.examineStatus" placeholder="待审核" @change="changeFun(item,idx)">
                <el-option label="待审核" value="待审核"></el-option>
                <el-option label="已审核" value="已审核"></el-option>
              </el-select>
            </van-col>
          </van-row>
        </div>
      </van-list>
    </div>
    <van-empty v-else description="暂无数据~" />

    <van-popup :close-on-click-overlay="false" v-model="timeshow" position="bottom" :style="{ height: '45%' }">
      <van-datetime-picker @cancel="timeshow = false" @confirm="settiem" v-model="currentDate" type="date"
        title="请选择日期" :formatter="formatter" />
    </van-popup>
  </div>
</template>

<script>
import { examinedetails,examineSh,examineShqx } from "@/api/check";
import moment from "moment";
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(from.path == '/examine'){
        vm.gid=vm.$route.query.gid
        vm.bizDate=vm.$route.query.bizDate
        vm.page = 0;
        vm.total = 0;
        vm.list = [];
        vm.getlist()
      }
    });
  },
  data() {
    return {
      list: [],
      gid: this.$route.query.gid,
      key: "",
      page: 0,
      size: 20,
      loading: false,
      total: 0,
      finished: false,
      isiOS: false,
      currentDate: new Date(this.$route.query.bizDate),
      bizDate:this.$route.query.bizDate,
      timeshow:false,
    };
  },
  methods: {
    toimgdan(img) {
      this.$router.push({
        name: "showimg",
        query: {
          img: img,
          idx: 0,
        },
      });
    },
    tourls(item) {
      if(item.carNo=='同行联合'){
        this.$router.push({
          path: "/bill_details",
          query: {
            gid: this.gid,
            source: item.source,
            accountNumber: item.sourceId,
          },
        });
      }else if(item.carNo=='代定联合'){
        if(!item.sourceId){
          this.$toast("作废订单不可查看！");
          return;
        }
        this.$router.push({
          path: "/pendingdetail",
          query: {
            gid: this.gid,
            id: item.sourceId
          },
        });
      }
    },
    onLoads() {
      this.page++;
      this.getlist();
    },

    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    timeshang() {
      this.page = 0;
      this.total = 0;
      this.list = [];
      this.bizDate = moment(this.bizDate)
        .subtract(1, "day")
        .startOf("day")
        .format("YYYY-MM-DD");
      this.getlist();
    },
    timexia() {
      this.page = 0;
      this.total = 0;
      this.list = [];
      this.bizDate = moment(this.bizDate)
        .startOf("day")
        .subtract("day", -1)
        .format("YYYY-MM-DD");
      this.getlist();
    },
    settiem(e) {
      this.bizDate = moment(e).format("YYYY-MM-DD");
      this.page = 0;
      this.total = 0;
      this.list = [];
      this.getlist();
      this.timeshow = false;
    },
    changeFun(item,idx){
      if(item.examineStatus=="待审核"){
        examineShqx({id:item.id}).then(res=>{
          if(res.code==200){
            this.$toast("成功取消审核");
          }else{
            this.list[idx].examineStatus = "已审核"
          }
        }).catch(err => {
          this.list[idx].examineStatus = "已审核"
        })
      }else{
        examineSh({id:item.id}).then(res=>{
          if(res.code==200){
            this.$toast("审核成功");
          }else{
            this.list[idx].examineStatus = "待审核"
          }
        }).catch(err => {
          this.list[idx].examineStatus = "待审核"
        })
      }
    },
    getlist() {
      const loading = this.$toast.loading({
        overlay: true,
        duration: 0,
        message: "加载中...",
      });
      var data = {
        gid: this.gid,
        // page: this.page,
        // size: this.size,
        bizDate:this.bizDate
      };
      examinedetails(data).then((e) => {
        loading.clear();
        if (e.code == 200) {
          let rows = e.data;
          this.loading = false;
          this.total = e.data.totalElements || rows.length;
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            this.list = []
            return;
          }
          this.list = rows
          this.finished = true;
          // 将新数据与老数据进行合并
          // this.list = this.list.concat(rows);
          // //如果列表数据条数>=总条数，不再触发滚动加载
          // if (this.list.length >= this.total) {
          //   this.finished = true;
          // }
        }
      });
    },

    back() {
      this.$router.go(-1);
    },
  },
  created() {
    this.getlist();
    //判断是否在微信环境

  },
  mounted() { },
  computed: {},
};
</script>
<style>
body {
  background: #f6f6f6;
}
</style>
<style lang="less" scoped>
.nav-bar {
  position: sticky;
  top: 46px;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
}

.topss {
  line-height: 35px;
  font-size: 17px;
  text-align: center;
  padding: 0 10px;
  background: #fff;
  color: #666;
}

.red {
  color: red;
  font-weight: bold;
}

.hedt {
  line-height: 35px;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  padding: 0 10px;
  background: #fff;
  // padding-top: 46px;
}

.flx {
  display: flex;
  align-items: center;
}

.pt {
  padding-top: 46px;
}

.mian {
  border-top: 1px solid #f5f5f5;
  border-radius: 6px;
  padding: 5px 0;
  background: #fff;
}

.mian_row {
  line-height: 35px;
  font-size: 13px;

  padding: 0 10px;
  background: #fff;
}

.bot {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;

  .van-button {
    width: 94%;
    margin: auto;
    display: block;
    font-size: 15px;
    height: 42px;
  }
}
.van-col{
  text-align: center;
}
::v-deep .cGreen{
  color: green;
  .el-input__inner,
  .el-select__caret{
    color: green;
  }
}
::v-deep .cRed{
  color: red;
  .el-input__inner,
  .el-select__caret{
    color: red;
  }
}
.carNo{
  font-size: 12px;
  &.line{
    text-decoration: underline;
    color: #5668ec;
  }
}
::v-deep .el-select {
  border: none;
  .el-input__inner{
    border: none;
    font-size: 12px;
    padding: 0;
    margin-right: -14px;
  }
}
::v-deep .el-select-dropdown__item{
  font-size: 13px;
}
</style>